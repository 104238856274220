interface page {
  [key: string]: any;
}

export default {
  Main: () => import('@/components/main/main.vue'),
  home: () => import('@/views/home/home.vue'),

  userList: () => import('@/views/userManagement/userList/userList.vue'),
  userDistribution: () => import('@/views/userManagement/userDistribution/index.vue'),

  channelList: () => import('@/views/channelManagement/channelList/channelList.vue'),
  pageList: () => import('@/views/channelManagement/pageList/pageList.vue'),
  channelGroup: () => import('@/views/channelManagement/channelGroup/channelGroup.vue'),
  channelStatistic: () => import('@/views/channelManagement/channelStatistic/channelStatistic.vue'),
  channelRevenueStatistics: () =>
    import('@/views/channelManagement/channelRevenueStatistics/channelRevenueStatistics.vue'),
  registerPageStatistics: () =>
    import('@/views/channelManagement/registerPageStatistics/registerPageStatistics.vue'),
  appLeaveInfoStatistic: () =>
    import('@/views/channelManagement/appLeaveInfoStatistic/appLeaveInfoStatistic.vue'),
  marketingManagement: () => import('@/views/channelManagement/marketingManagement/index.vue'),

  productList: () => import('@/views/productManagement/productList/productList.vue'),
  productIncome: () => import('@/views/productManagement/productIncome/productIncome.vue'),
  channelProductConfig: () =>
    import('@/views/productManagement/channelProductConfig/channelProductConfig.vue'),

  dataOverview: () => import('@/views/dataManagement/dataOverview/dataOverview.vue'),

  operateData: () => import('@/views/operateManagement/operateData/operateData.vue'),
  operatePositionManege: () =>
    import('@/views/operateManagement/operatePositionManege/operatePositionManege.vue'),
  popUpStatistic: () => import('@/views/operateManagement/popUpStatistic/popUpStatistic.vue'),
  dataMarket: () => import('@/views/operateManagement/dataMarket/index.vue'),
  feedback: () => import('@/views/operateManagement/feedback/index.vue'),
  userBehaviorData: () => import('@/views/operateManagement/userBehaviorData/index.vue'),

  adminUser: () => import('@/views/systemManagement/user/user.vue'),
  adminRole: () => import('@/views/systemManagement/role/role.vue'),
  sysModule: () => import('@/views/systemManagement/sysModule/sysModule.vue'),
} as page;
