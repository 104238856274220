import { defineStore } from 'pinia';

export const mainStore = defineStore('main', {
  state: () => {
    return {
      init: false,
      menuList: [],
      breadCrumbList: [],
      layoutWidth: 0,

      previewPictureModalFlag: false,
      previewPictureModalImgSrc: [],

      formModalFlag: false,
      formModalRequestInfo: {},
    };
  },

  getters: {
    getLayoutWidth(state) {
      return state.layoutWidth as number;
    },
  },

  actions: {
    setBreadCrumb(to: any) {
      const matched = to.matched;
      const metaArr = matched.filter((item: any) => {
        return item.meta !== undefined;
      });
      const arr: Array<any> = [];
      metaArr.forEach((item: any) => {
        arr.push(item.meta.title);
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.breadCrumbList = arr;
    },

    previewPictureModalClick(to: boolean, url = '') {
      this.previewPictureModalFlag = to;
      this.previewPictureModalImgSrc = Object.assign([], [url]);
    },
  },
});
