/**
 * @author: zhangyh-k@glondon.com
 * @description:
 * @Date: 2020/1/14 22:08
 */
!(function f() {
  let wH = window.innerHeight;
  let wW = window.innerWidth;
  let whdef = 100 / 1920;
  if (wW > 1400) {
    let rem = wW * whdef;
    let html = document.documentElement;
    html.style.fontSize = rem + 'px';
  } else {
    let rem = 1400 * whdef;
    let html = document.documentElement;
    html.style.fontSize = rem + 'px';
  }
})();
