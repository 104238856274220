const runType = import.meta.env.APP_FLAG;
let apiPrefixPath = '';
let SIGNKEY = '';
export let ossPath = '';
switch (runType) {
  case 'pro':
    apiPrefixPath = 'https://cms.swiftfintech.com/cms';
    ossPath = 'https://oss.swiftfintech.com';
    SIGNKEY = 'EDH6sOgEILMSMPkJt78ilfMUpJ5FajXJ';
    break;
  case 'test':
    apiPrefixPath = 'https://test-cms.swiftfintech.com/cms';
    ossPath = 'https://oss.swiftfintech.com';
    SIGNKEY = 'EDH6sOgEILMSMPkJt78ilfMUpJ5FajXJ';
    break;
  default:
    apiPrefixPath = 'https://test-cms.swiftfintech.com/cms';
    ossPath = 'https://oss.swiftfintech.com';
    SIGNKEY = 'EDH6sOgEILMSMPkJt78ilfMUpJ5FajXJ';
    break;
}
export default {
  apiPrefixPath,
  SIGNKEY,
  ossPath,
};
