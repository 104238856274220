export const Common = {
  fileUpload: `/upload/image`,
  apkUpload: `/upload/apk`,
  excelUpload: `/upload/excel`,
  getCityALLData: `/channel/cityConstant/list`,
  selectMetadataField: '/product/user/selectMetadataField',
};

export const Auth = {
  sendSms: `/login/sms.e`,
  login: `/login/login.e`,
  loginPass: `/login/password.e`,
};

export const ChannelGroup = {
  pageList: '/channelGroup/pageList',
  list: '/channelGroup/list',
  selectById: '/channelGroup/selectById',
  update: '/channelGroup/update',
  insert: '/channelGroup/insert',
};

export const Channel = {
  channelPageList: `/channel/pageList`,
  channelList: `/channel/list`,
  channelDetail: `/channel/detail`,
  channelSave: `/channel/save`,
  channelUpdate: `/channel/update`,
  merchant: '/channel/merchant',
  merchantExport: '/channel/merchant/export',
  merchantStatistics: '/channel/merchantStatistics.e',
  merchantStatisticsExport: '/channel/merchantStatistics/export.e',
  insertWdBasics: '/product/insertWdBasics',
  updateWdBasics: '/product/updateWdBasics',
  selectApiMetadataField: '/product/user/selectApiMetadataField',
  updateWdApi: '/product/updateWdApi',
  updateWdProductCapital: '/product/updateWdProductCapital',
};

export const DomainBody = {
  page: '/domainBody/page',
  list: '/domainBody/list',
  selectById: '/domainBody/selectById',
  update: '/domainBody/update',
  insert: '/domainBody/insert',
  delete: '/domainBody/delete',
};

export const ChannelRegisterPage = {
  list: '/channelRegisterPage/list',
  pageList: '/channelRegisterPage/pageList',
  selectById: '/channelRegisterPage/selectById',
  update: '/channelRegisterPage/update',
  insert: '/channelRegisterPage/insert',
  delete: '/channelRegisterPage/delete',
  productList: '/product/allProduct',
};

export const System = {
  sysUserPage: '/sys/user/page',
  sysUserInsert: '/sys/user/insert',
  sysUserUpdate: '/sys/user/update',
  sysUserDetail: '/sys/user/detail',
  sysUserRole: '/sys/userRole',
  sysRoleInsert: '/sys/role/insert',
  sysRoleUpdate: '/sys/role/update',
  sysRoleDelete: '/sys/role/delete',
  sysRole: '/sys/role',
  sysRoleAndMenu: '/sys/roleandmenu',
  sysRoleDetail: '/sys/role/detail',
  sysMenuInsert: '/sys/menu/insert',
  sysMenuUpdate: '/sys/menu/update',
  sysMenuList: '/sys/menu/list',
  sysSelectUserMenus: '/sys/selectUserMenus',
  sysMenu: '/sys/menu',
  sysMenuDetail: '/sys/menu/detail',
  sysRoleMenuList: '/sys/roleMenu/list',
};

export const User = {
  page: `/user/pageList`,
  detail: `/user/detail`,
  export: '/user/export',
  queryCondition: '/user/queryCondition',
  queryUserCapitalByCondition: '/user/queryUserCapitalByCondition',
};

export const ChannelThird = {
  thirdOperate: `/channel/thirdOperate.e`,
  thirdOperateExport: `/channel/thirdOperate/export.e`,
  selectOperateDetail: `/channel/selectOperateDetail`,
};

export const Product = {
  pageList: '/product/pageList',
  del: '/product/del',
  detail: '/product/detail',
  insert: '/product/insert',
  update: '/product/update',
  updateStatus: '/product/updateStatus',
  auditPageList: '/product/auditPageList',
  auditStatus: '/product/auditStatus',
  bankConfigResult: '/product/bankConfigResult',
  detailWdApi: '/product/detailWdApi',
  queryBySysParamKey: '/sysParam/queryBySysParamKey',
  insertOrUpdate: '/sysParam/insertOrUpdate',
};

export const ApplicationMarket = {
  pageInfoList: '/appInfo/pageInfoList',
  del: '/appInfo/del',
  insert: '/appInfo/insert',
  detail: '/appInfo/detail',
  update: '/appInfo/update',
};

export const CountChannel = {
  count: '/count/channel/count',
  export: '/count/channel/export',
};

export const ChannelStatis = {
  count: '/count/channel/earnings/count',
  export: '/count/channel/earnings/export',
  apiCount: '/count/channel/earnings/apiDetail',
  apiExport: '/count/channel/earnings/apiDetail/export',
  actualCost: '/count/channel/earnings/actualCost',
  apiActualCost: '/count/channel/earnings/apiActualCost',
};

export const CountProductIncome = {
  count: '/count/product/count',
  export: '/count/product/export',
  income: '/count/product/actualRevenue',
  inputPieceCount: '/count/product/pushDetail',
  inputPieceExport: '/count/product/pushDetail/export',
};

export const CountTotal = {
  count: '/count/total/count',
  export: '/count/total/export',
};

export const CountHome = {
  card: '/countHome/selectCount',
};

export const CountOperate = {
  count: '/count/Operate/count',
  export: '/count/Operate/export',
};

export const Operate = {
  pageList: '/operate/pageList',
  detail: '/operate/detail',
  insert: '/operate/insert',
  update: '/operate/update',
  delete: '/operate/del',
  operateTypeList: '/operate/operateTypeList',
  operateLocationList: '/operate/operateLocationList',
  operateUrlList: '/operate/operateUrlList',
  userComplain: 'userComplain/list',
  complainDetail: 'userComplain/detail',
  complainUpdate: 'userComplain/update',
  queryDataDashboard: '/count/Operate/queryDataDashboard',
  appCallback: '/count/appCallback',
  actualCost: '/count/appCallback/actualCost',
  appChannelData: '/count/appChannelData',
};

export const CountRegister = {
  pageCount: '/count/register/page/count',
  pageCountExport: '/count/register/page/export',
  pageDownloadCount: '/count/register/page/downloadCount',
  pageDownloadExport: '/count/register/page/downloadExport',
  registerPageCountTotal: '/count/registerPage/countTotal',
  registerPageExportCountTotal: '/count/registerPage/exportCountTotal',
  registerPageCountPage: '/count/registerPage/countPage',
  registerPageExportCountPage: '/count/registerPage/exportCountPage',
  registerPageCountChannel: '/count/registerPage/countChannel',
  registerPageExportCountChannel: '/count/registerPage/exportCountChannel',
  registerPageExportCountPageDetail: '/count/registerPage/countPageDetail',
  registerPageExportExportCountPageDetail: '/count/registerPage/exportCountPageDetail',
  registerPageExportCountChannelDetail: '/count/registerPage/countChannelDetail',
  registerPageExportExportCountChannelDetail: '/count/registerPage/exportCountChannelDetail',
};

export const ChannelProductConfig = {
  pageList: '/channelProductConfig/pageList',
  detail: '/channelProductConfig/detail',
  update: '/channelProductConfig/update',
};

export const AppRemainCount = {
  countTotal: '/count/appRemain/countTotal',
  exportCountTotal: '/count/appRemain/exportCountTotal',
};
