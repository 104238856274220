import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouteLocationNormalized,
  NavigationGuardNext,
  RouteRecordNormalized,
} from 'vue-router';
import Login from '@/views/login/login.vue';
import notFund from '@/views/error-page/404.vue';
import basics from '@/libs/basics.js';
import { mainStore } from '@/store';
import { routerMenu } from './getMenu.js';
import { tsTypes } from '@/libs/interfaceType';
import _ from 'lodash-es';
import page from '@/router/page';
import localRouter from '@/router/localRouter';
import http from '@/libs/service/http';
import { ossPath } from '@/libs/api';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/404',
    name: '404',
    component: notFund,
    meta: {
      title: '404',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: '登录',
    },
  },
  {
    path: '/channelBackground',
    name: 'channelBackground',
    component: () => import('@/views/channelManagement/channelList/channelLinkTable.vue'),
    meta: {
      title: '渠道商链接',
    },
  },
  // ...localRouter,
];

const router = createRouter({
  routes,
  history: createWebHistory(),
  scrollBehavior() {
    return { top: 0 };
  },
});

export const promise: string[] = ['channelBackground'];

router.beforeEach(
  async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    if (
      window.location.href.indexOf('test') > -1 ||
      window.location.href.indexOf('localhost') > -1 ||
      window.location.href.indexOf('192') > -1
    ) {
      document.title = '测试环境-后台管理系统';
    }
    const store = mainStore();

    if (promise.indexOf(to.name as string) > -1) {
      next();
    } else if (!basics.Storage.isLogin()) {
      if (to.path !== '/login') {
        next({ path: '/login' });
      } else {
        next();
      }
    } else if (basics.Storage.isLogin() && to.path === '/login') {
      next({ path: '/' });
    } else {
      saveNavTag(to);
      if (!store.init) {
        const obj: any = await routerMenu();
        const { routerList } = _.cloneDeep(obj);
        routerList.forEach((value: RouteRecordNormalized) => {
          router.addRoute(value);
        });
        store.menuList = routerList;
        const path = to.path === '/' ? routerList[0].children[0].path : to.path;
        next({ path: path, replace: true });
      } else {
        next();
      }
    }
  }
);

interface keyValueParams {
  name: string;
  title: string;
}

function saveNavTag(route: RouteLocationNormalized) {
  if (route.name) {
    const obj = { name: route.name, title: route.meta.title };
    const arr = basics.Storage.getLocal('tagNavList') || [];
    const flag = arr.find((item: keyValueParams) => {
      return item.name === obj.name;
    });
    if (!flag) {
      arr.push(obj);
    }
    basics.Storage.setLocal('tagNavList', arr);
  }
}

router.afterEach((route) => {
  window.scrollTo(0, 0);
});

export default router;
